import { Button } from '@c1/gravity-react';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CloseButton from 'src/components/buttons/CloseButton/CloseButton';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { Environment } from 'src/types/environment';
import {
  AuthenticationContextState,
  useAuthenticationContext
} from 'src/contextProviders/AuthenticationProvider/AuthenticationContext';
import {
    Action,
    init,
    LiveboardEmbed,
    AuthType,
    EmbedEvent
} from "@thoughtspot/visual-embed-sdk"
import "./ThoughtSpotDashboard.css";
import axios from 'axios';
import { navigate, useLocation, useParams } from '@reach/router';
import { useEnvironment } from 'src/contextProviders/EnvironmentProvider/EnvironmentContext';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import { isMobile } from 'react-device-detect';
import iframeResolutionHelper from 'src/utils/iframeResolutionHelper';

interface stateType {
  title: string;
}

function ThoughtSpotLiveBoard(): FunctionComponentReturnType {

  const routeBuilder: RouteBuilder = useRouteBuilder();
  const [tsAuthExpired, setTsAuthExpired] = useState<boolean>(false);
  const [errorOnEmbedView, setErrorOnEmbedView] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>('Something went wrong. Please contact the support team');
  const { pinboardId } = useParams();
  const location = useLocation();
  const { user }: AuthenticationContextState = useAuthenticationContext();
  const { thoughtspotHostURL }: Environment = useEnvironment();
  const apiRoute: string = routeBuilder.api.getThoughtspotAuthEntity({ pinboardId });
  const apiRouteFail: string = routeBuilder.api.trackDashboardFail({ dashboardId: pinboardId });

  // Functions to show/hide
  function setDisplayStyle(el: any, style: any) {
    if (el != null && document.getElementById(el) != null) {
      document.getElementById(el)!.style.display = style;
    }
  }

  function showLoader() {
    setDisplayStyle("loader", "block");
  }

  function hideLoader() {
    setDisplayStyle("loader", "none");
  }

  function showAuthExpired() {
    setDisplayStyle("authExpiredBanner", "flex");
    setTsAuthExpired(true);
    window.location.assign(routeBuilder.client.toHomePage());
  }

  // Functions to show or hide No data images
  function showNoDataImage() {
    setDisplayStyle("no-data", "block");
  }

  function hideNoDataImage() {
    setDisplayStyle("no-data", "none");
  }
  useEffect(() => {
    iframeResolutionHelper(null, (iframeDoc, status) =>{
    let shouldSendFail = false;
    console.log("Status: " + status)
    if(status && Math.floor(status/100) != 2) {
          shouldSendFail = true;
          console.log("Dashboard '" + pinboardId + "' failed because status=" + status);
    }
    if(iframeDoc) {
      try {
          var element = iframeDoc.getElementsByTagName("app-controller")
          shouldSendFail = !element;
      } catch (e) {
          console.log("Dashboard '" + pinboardId + "' failed because error=" + e)
          shouldSendFail = true;
      }
    } else {
          shouldSendFail = true;
          console.log("Dashboard '" + pinboardId + "' failed because iframe was not accessible")
    }
    if (shouldSendFail) {
          console.log("Dashboard '" + pinboardId + "' failed to load, sending error report")
          axios.post(apiRouteFail);
    } else {
        console.log("Dashboard '" + pinboardId + "' looks to have loaded successfully")
    }
    })
  }, [])

  let titleObject: any = !!location.state && typeof (location.state) === "object" && location.state != null ? location.state : { title: "Thoughtspot View" };
  useEffect(() => {
    if (document.getElementById("page-template-title-element") && document.getElementById("page-template-title-element")!.textContent)
      document.getElementById("page-template-title-element")!.textContent = titleObject.title;
    axios.get(apiRoute).then(result => {
      let tokenResult = result.data.toString();
      initThoughtSpot(tokenResult);

    }).catch(error => {
      setErrorOnEmbedView(true);
      console.log(error.response.status);
      if (error.response.status === 400 || error.response.status === 404 || error.response.status === 500 || error.response.status === 503) {
        setErrorMessage('Sorry - we are currently unable to process your request.  Please try again in a few minutes');
      }
      console.error(error);
    });
  }, []);

  async function initThoughtSpot(tokenResult: string) {
    init({
      thoughtSpotHost: window.location.origin + '/thoughtspot', // Your instance here.
      authType: AuthType.TrustedAuthToken, // Your Auth scheme, SAML or AuthToken
      username: user?.userId,
      getAuthToken: (async () => {
        return tokenResult;
      })
    });

    const embed = new LiveboardEmbed("#thoughtspot-embed", {
      fullHeight: true,
      liveboardId: pinboardId,
      frameParams: {
        width: '100%',
        height: '100%',
      },
      visibleActions: (isMobile) ? [] : [Action.Download, Action.DownloadAsCsv],
    });
    embed
      .on(EmbedEvent.Init, showLoader)
      .on(EmbedEvent.Load, hideLoader)
      .on(EmbedEvent.AuthExpire, showAuthExpired)
      .on(EmbedEvent.Error, () => {
        showNoDataImage();
        hideLoader();
      }).render();

  }

  return (
    <div>
      <Button id={'copp-button-return'} className="grv-margin--tiny" type="regressive" onClick={() => navigate(routeBuilder.client.toDashboardSelectionPage())} compact>Return</Button>
      <br />
      <div>
        <main>
          <div className="liveboard-container-div">
            {errorOnEmbedView ?
              <p>{errorMessage}</p>
              :
              <div id="thoughtspot-embed" />
            }
          </div>
        </main>
      </div>
      <InformationModal
        description="Please refresh the page."
        isOpen={tsAuthExpired}
        title="ThoughtSpot Session Expired"
        onClose={() => window.location.reload()}
      />
    </div>
  );
}

export default ThoughtSpotLiveBoard;
